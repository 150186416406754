import React from 'react'
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SoftwareUpdates from "../components/SoftwareUpdates"
import Video from "../components/VideoEmbed"
import software_update from '../../content/assets/page_images/software_update_page.webp'
import SEO from "../components/seo"

const SoftwareUpdate = (props) => {
  const data = useStaticQuery(graphql`
  {
    site {
      siteMetadata {
        title
        slogan
      }
    }
  }
`)
const siteTitle = data.site.siteMetadata.title
const siteSlogan = data.site.siteMetadata.slogan
const frontmatter = {
  slug: props.location.pathname.replace(/[^\w\d-]/g, '')
};
  return(
    <Layout  title={siteTitle} slogan={siteSlogan}>
      <SEO
          title="Instructions On How To Update The Software For PW"
          description="This page will help you to update your PW device. All necessary software and firmware is linked here."
          image={software_update}
          frontmatter={frontmatter}
        />
      <main>
        <h1 className="blog-heading">Software Updates</h1>
        <p>Software for Android:</p>
        <p>
          <a href="/powerWatcher2_4-1.zip">powerWatcher2_4-1.zip</a>
        </p>
        <p>
          <a href="/powerWatcher2_7.apk">powerWatcher2_7.apk</a>
        </p>
        <p>
          <a href="/powerWatcher2_7_1.apk">powerWatcher2_7_1.apk</a>
        </p>
        <p>
          <a href="/powerWatcher_3_3.apk">powerWatcher3_3.apk</a>
        </p>
        <p>Firmware updates for the device itself:</p>
        <p>
          <a href="/pwu_2_05-1.zip">pwu_2_05-1.zip</a>
        </p>
        <p>
          <a href="/pwu_2_13.em1">pwu_2_13.em1</a>
        </p>
        <p>How to update Power Watcher software:</p>
        <Video
          videoSrcURL="https://www.youtube.com/embed/V-jBUeOTx2E"
          videoTitle="How to update Power Watcher Software"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          allowFullScreen
          width="800"
          height="600"
        />
        <SoftwareUpdates></SoftwareUpdates>
      </main>
    </Layout>
  )
}

export default SoftwareUpdate